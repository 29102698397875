import React from "react"
import Layout from "../components/MainLayout"
import styled from "styled-components"
import PageHeading from "../components/PageHeadings"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const PageLayout = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`

const ImagesLayoutContainer = styled.div`
  display: flex;
  margin: 2rem auto;
  justify-content: space-around;
  flex-wrap: wrap;
`
const ImageContainer = styled.div`
  width: 400px;
  height: 300px;
  margin: 0.5rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ImagesPage = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allFile(filter: { relativeDirectory: { eq: "machineImages" } }) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <PageHeading pageHeading="Images" />
      <PageLayout>
        <ImagesLayoutContainer>
          {data.allFile.edges.map(({ node }) => (
            <ImageContainer>
              <img src={node.childImageSharp.fluid.src} />
            </ImageContainer>
          ))}
        </ImagesLayoutContainer>
      </PageLayout>
    </Layout>
  )
}

export default ImagesPage
